<template>
  <v-btn color="primary" elevation="0" :to="route">
    <span class="pl-3 text-h6">{{ $t(keyTranslate) }}</span>
    <v-icon>mdi-chevron-right</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "GoToButtonComponent",
  props: {
    keyTranslate: { type: String, required: true },
    route: { type: Object, required: true },
  },
};
</script>
